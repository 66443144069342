
import Pitch, {Pitches} from '@/models/Pitch';
import {PropType, defineComponent} from 'vue';
import CollectionTable from '@/components/common/model-collection/CollectionTable.vue';
import OptionsDropdown from '@/components/pitches/OptionsDropdown.vue';
import Team from '@/models/Team';
import TimeAgo from '@/components/common/TimeAgo.vue';
import authStore from '@/store/auth';

export default defineComponent({
    components: {
        CollectionTable,
        OptionsDropdown,
        TimeAgo,
    },
    props: {
        headers: {
            type: Array as PropType<string[]>,
            default: () => [
                'name',
                'updatedAt',
                'createdAt',
            ],
        },
        pitches: {
            type: Object as PropType<Pitches>,
            required: true,
        },
    },
    setup() {
        return {
            user: authStore.me,
        };
    },
    methods: {
        goToPitch(pitch: Pitch) {
            this.$router.push(pitch.getLocation());
        },
        goToTeam(teamId: number) {
            const team = new Team({id: teamId});

            this.$router.push(team.getLocation());
        },
    },
});
