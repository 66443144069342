
import {defineComponent, ref} from 'vue';
import BtnCreateModal from '@/components/pitchMoments/BtnCreateModal.vue';
import PitchMoment from '@/models/PitchMoment';
import {Pitches} from '@/models/Pitch';
import PitchesTable from '@/components/pitches/Table.vue';
import {Portal} from 'portal-vue';

export default defineComponent({
    components: {
        BtnCreateModal,
        PitchesTable,
        Portal,
    },
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    setup(props) {
        return {
            headers: [
                'team',
                'pitch',
                'updatedAt',
            ],
            options: {
                url: `pitch-moments/${props.id}/pitches`,
            },
            pitches: ref<Pitches>(new Pitches()),
            pitchMoment: ref<PitchMoment>(new PitchMoment({
                id: +props.id,
            })),
        };
    },
    created() {
        this.pitchMoment.fetch();
    },
});
