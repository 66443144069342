import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center h-100" }
const _hoisted_2 = { class: "ms-6" }
const _hoisted_3 = { class: "mt-12 px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_BtnCreateModal = _resolveComponent("BtnCreateModal")!
  const _component_Portal = _resolveComponent("Portal")!
  const _component_PitchesTable = _resolveComponent("PitchesTable")!
  const _component_WaitForResource = _resolveComponent("WaitForResource")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Portal, { to: "navbar-top-content" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_RouterLink, {
            class: "btn px-0",
            to: {name: 'home'}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Fa, { icon: "arrow-left" })
            ]),
            _: 1
          }),
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('pitchMoments.show.title')), 1),
          _createVNode(_component_BtnCreateModal, { class: "ms-auto" })
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_WaitForResource, { resource: _ctx.pitchMoment }, {
      default: _withCtx(() => [
        _createElementVNode("h3", null, _toDisplayString(_ctx.pitchMoment.name), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_PitchesTable, {
            headers: _ctx.headers,
            options: _ctx.options,
            pitches: _ctx.pitches
          }, null, 8, ["headers", "options", "pitches"])
        ])
      ]),
      _: 1
    }, 8, ["resource"])
  ]))
}