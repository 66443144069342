
import {PropType, defineComponent, ref} from 'vue';
import {hideModal, showModal} from '@/library/helpers';
import BtnResource from '@/components/common/model-collection/BtnResource.vue';
import Dropdown from '@/components/common/Dropdown.vue';
import {DropdownOption} from '@/components/common/DropdownMenu.vue';
import Modal from '@/components/common/Modal.vue';
import Pitch from '@/models/Pitch';
import {t} from '@/vendor/I18n';

export default defineComponent({
    components: {
        BtnResource,
        Dropdown,
        Modal,
    },
    props: {
        pitch: {
            type: Object as PropType<Pitch>,
            required: true,
        },
    },
    setup(props) {
        return {
            deleteModalName: ref<string>(`delete-pitch-${props.pitch.id}`),
            dropdownOptions: [
                {
                    key: 'deletePitch',
                    label: t('pitch.actions.deletePitch'),
                },
            ] as DropdownOption[],
        };
    },
    methods: {
        onOptionSelected() {
            showModal(this.deleteModalName);
        },
        async deletePitch() {
            try {
                await this.pitch.delete();
            } catch (e: any) {
                if (!e.response || !e.response.data.errors) throw e;

                return;
            }

            hideModal(this.deleteModalName);
        },
    },
});
