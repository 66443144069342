import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "border-top mt-12 pt-6 text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BtnResource = _resolveComponent("BtnResource")!
  const _component_PitchMomentForm = _resolveComponent("PitchMomentForm")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    name: _ctx.name,
    "no-footer": "",
    size: "xl",
    title: _ctx.$t('pitchMoments.create.title')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PitchMomentForm, {
        class: "text-start",
        "show-tooltip": _ctx.showTooltip,
        onSavedPitchMoment: _ctx.goToPitchMoment
      }, {
        "save-button": _withCtx(({disabled, resource}) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("button", {
              class: "btn btn-outline-light",
              type: "button",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideCreateModal && _ctx.hideCreateModal(...args)))
            }, _toDisplayString(_ctx.$t('actions.cancel')), 1),
            _createVNode(_component_BtnResource, {
              class: "ms-4",
              disabled: disabled,
              resource: resource,
              type: "submit"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('pitchMoments.actions.addPitchMoment')), 1)
              ]),
              _: 2
            }, 1032, ["disabled", "resource"])
          ])
        ]),
        _: 1
      }, 8, ["show-tooltip", "onSavedPitchMoment"])
    ]),
    _: 1
  }, 8, ["name", "title"]))
}