import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32d01d09"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "d-inline-flex justify-content-center align-items-center square-md rounded pitch-icon"
}
const _hoisted_3 = { class: "fw-bold ms-6" }
const _hoisted_4 = {
  key: 2,
  class: "badge badge-faded bg-primary ms-4"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "ms-6" }
const _hoisted_8 = { class: "text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_TimeAgo = _resolveComponent("TimeAgo")!
  const _component_OptionsDropdown = _resolveComponent("OptionsDropdown")!
  const _component_CollectionTable = _resolveComponent("CollectionTable")!

  return (_openBlock(), _createBlock(_component_CollectionTable, {
    collection: _ctx.pitches,
    headers: _ctx.headers,
    onRowClicked: _ctx.goToPitch
  }, {
    name: _withCtx(({row}) => [
      _createElementVNode("td", null, [
        (row.team?.logo)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "square-md rounded",
              "data-test-id": "image-team",
              src: row.team.logo.thumbXs
            }, null, 8, _hoisted_1))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_Fa, {
                class: "text-warning",
                icon: "file",
                size: "lg"
              })
            ])),
        _createElementVNode("span", _hoisted_3, _toDisplayString(row.name), 1),
        (row.isSharedWithUser)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('pitch.index.shared')), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    pitch: _withCtx(({row}) => [
      _createElementVNode("td", null, _toDisplayString(row.name), 1)
    ]),
    team: _withCtx(({row}) => [
      _createElementVNode("td", null, [
        _createElementVNode("div", {
          class: "d-inline-block",
          onClick: _withModifiers(($event: any) => (_ctx.goToTeam(row.team.id)), ["stop"])
        }, [
          _createElementVNode("img", {
            class: "square rounded-1",
            src: row.team.logo?.thumbSm
          }, null, 8, _hoisted_6),
          _createElementVNode("span", _hoisted_7, _toDisplayString(row.team.name), 1)
        ], 8, _hoisted_5)
      ])
    ]),
    pitchMoment: _withCtx(({row}) => [
      _createElementVNode("td", null, _toDisplayString(row.pitchMoment?.name), 1)
    ]),
    updatedAt: _withCtx(({row}) => [
      _createElementVNode("td", null, [
        _createVNode(_component_TimeAgo, {
          date: row.updatedAt
        }, null, 8, ["date"])
      ])
    ]),
    createdAt: _withCtx(({row}) => [
      _createElementVNode("td", null, [
        _createVNode(_component_TimeAgo, {
          date: row.createdAt
        }, null, 8, ["date"])
      ])
    ]),
    options: _withCtx(({row}) => [
      _createElementVNode("td", _hoisted_8, [
        (row.userId === _ctx.user.id)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createVNode(_component_OptionsDropdown, { pitch: row }, null, 8, ["pitch"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["collection", "headers", "onRowClicked"]))
}