
import {defineComponent, ref} from 'vue';
import BtnResource from '@/components/common/model-collection/BtnResource.vue';
import Modal from '@/components/common/Modal.vue';
import PitchMoment from '@/models/PitchMoment';
import PitchMomentForm from '@/components/pitchMoments/Form.vue';
import {hideModal} from '@/library/helpers';
import onboardingStore from '@/store/onboarding';

export default defineComponent({
    components: {
        BtnResource,
        Modal,
        PitchMomentForm,
    },
    setup() {
        return {
            name: 'pitch-moment-create',
            showTooltip: ref<boolean>(false),
        };
    },
    mounted() {
        this.$el.addEventListener('shown.bs.modal', this.toggleShowTooltip);
        this.$el.addEventListener('hidden.bs.modal', this.toggleShowTooltip);
    },
    beforeUnmount() {
        this.$el.removeEventListener('shown.bs.modal', this.toggleShowTooltip);
        this.$el.removeEventListener('hidden.bs.modal', this.toggleShowTooltip);
    },
    methods: {
        toggleShowTooltip() {
            this.showTooltip = !this.showTooltip;
        },
        hideCreateModal() {
            hideModal(this.name);
        },
        goToPitchMoment(pitchMoment: PitchMoment) {
            onboardingStore.completeStep('createPitchMoment');

            this.hideCreateModal();

            this.$router.push(pitchMoment.getLocation());
        },
    },
});
