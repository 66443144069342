import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "btn btn-square",
  "data-bs-toggle": "dropdown"
}
const _hoisted_2 = { class: "text-center whitespace-pre-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_BtnResource = _resolveComponent("BtnResource")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Dropdown, _mergeProps(_ctx.$attrs, {
      align: "end",
      "emit-key": "key",
      options: _ctx.dropdownOptions,
      onOptionSelected: _ctx.onOptionSelected
    }), {
      "dropdown-toggle": _withCtx(() => [
        _createElementVNode("button", _hoisted_1, [
          _createVNode(_component_Fa, { icon: "ellipsis-v" })
        ])
      ]),
      _: 1
    }, 16, ["options", "onOptionSelected"]),
    _createVNode(_component_Modal, {
      "cancel-title": _ctx.$t('actions.cancel'),
      name: _ctx.deleteModalName,
      "no-close-btn": "",
      "ok-title": _ctx.$t('pitch.actions.iAmSureDelete'),
      title: _ctx.$t('pitch.deletePitch.title')
    }, {
      "ok-button": _withCtx(({title}) => [
        _createVNode(_component_BtnResource, {
          resource: _ctx.pitch,
          onClick: _ctx.deletePitch
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(title), 1)
          ]),
          _: 2
        }, 1032, ["resource", "onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('pitch.deletePitch.body')), 1)
      ]),
      _: 1
    }, 8, ["cancel-title", "name", "ok-title", "title"])
  ], 64))
}